<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          {{ PdmMaItemEntity.ItemName!="" ? "修改项目":"新增项目"   }}
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          label="项目编码"
          dense
          outlined
          class="col-11"
          v-model="PdmMaItemEntity.ItemCode"
        /> 
      </div>
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          label="项目名称"
          dense
          outlined
          class="col-11"
          v-model="PdmMaItemEntity.ItemName"
        /> 
      </div>
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          label="内容介绍"
          dense
          outlined
          class="col-11"
          v-model="PdmMaItemEntity.ItemContent"
        /> 
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";
export default {
  components: {
    // select_hospInfo,
  }, 
  props: {},
  computed: {
    ...mapState("PdmMaItem", ["PdmMaItemShowDlg", "PdmMaItemEntity", "PdmMaItemList"]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("PdmMaItem", ["actUpdatePdmMaItem", "actGetPdmMaItemList"]),
    onOK() {
      this.actUpdatePdmMaItem({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        ItemID: this.PdmMaItemEntity.ItemID,
        Info: this.PdmMaItemEntity,
      }).then((res) => {
        if (res.Code == 0) {
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
